(function ($) {
  Drupal.behaviors.productAccordionArea = {
    attached: false,
    attach: function (context) {
      var $accordionPanel = $('.js-accordion_panel', context);

      if ($accordionPanel.length < 1) {
        return;
      }

      var readMoreString = site.translations.product.product_description_more;
      var readLessString = site.translations.product.product_description_less;
      var characterLimit = $accordionPanel.data().characterLimit || 250;
      var $longDesc = $accordionPanel.find('.js-product-full__long_description');
      var $longText = $longDesc.length > 0 ? trimHtml($longDesc.html()) : '';
      var hasLongDesc = $longText.length > 0;
      var isLongText = hasLongDesc && $longText.length > characterLimit;
      var fullContent = isLongText ? $longText : '';
      var shortContent = isLongText ? $longText.substring(0, characterLimit) : '';
      var fullHeight;
      var shortHeight;

      // Read More after accordion loads avoids targeting collapsed container
      $(document).on('accordion.init', '.accordions', function () {
        // Get and set heights. truncate text
        if (isLongText) {
          fullHeight = $longDesc.height();
          $longDesc.html(shortContent + '...');
          shortHeight = $longDesc.height();
          $longDesc.css({ height: shortHeight + 'px' });
        }

        // Execute toggle link and exit if already loaded
        if ($(document).data('readyExecuted')) {
          if (isLongText) {
            $longDesc.html(shortContent + '...');
          }
          readMoreToggle();

          return;
        }

        // Set data attribute to show completion
        $(document).data('readyExecuted', true);

        readMoreToggle();
      });

      // Trim excess whitespace from start/end which skews character count
      function trimHtml(html) {
        var htmlTrim = html.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');

        return htmlTrim;
      }

      function readMoreToggle() {
        if (isLongText) {
          // Define self and toggleContent
          var self = {
            toggleContent: function () {
              if ($longDesc.hasClass('expanded')) {
                $longDesc.css({ height: shortHeight + 'px' });
                $longDesc.removeClass('expanded');
                setTimeout(function () {
                  $longDesc
                    .html(shortContent + '...')
                    .siblings('.js-product-full__more-cta')
                    .html(readMoreString);
                }, 800);
              } else {
                $longDesc
                  .html(fullContent)
                  .addClass('expanded')
                  .siblings('.js-product-full__more-cta')
                  .html(readLessString);
                $longDesc.css({ height: fullHeight + 'px' });
              }
            }
          };

          // Click handler for link
          $(document).on('click', '.js-product-full__more-cta', function (e) {
            e.preventDefault();
            self.toggleContent();
          });

          // Append link if not already appended
          if ($('.js-product-full__more-cta').length === 0) {
            $longDesc.after(
              $('<a/>', {
                class: 'more js-product-full__more-cta',
                html: readMoreString,
                href: '#'
              })
            );
          }
        } else {
          $longDesc.addClass('clear-gradient');
        }
      }

      // Collapsable Accordion
      $('.accordion .accordion_btn', context).on('click', function () {
        $(this).toggleClass('active').siblings('.accordion_panel').stop(true, true).slideToggle();
      });

      // Show the 1st item in the accordion that is visible
      $('.product-full__accordion .accordion:visible:first-child .accordion_btn').trigger('click');
      $('.accordions', context).trigger('accordion.init');
    }
  };
})(jQuery);
